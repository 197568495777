import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressFormValues} from '../../forms/address-form/address-form.component';
import {Form990ScheduleOLineFormValues} from './form990-schedules-form.service';
import {PaidPreparerFormValues} from '../../forms/paid-preparer-form/paid-preparer-form.component';
import {requiredFileType} from '../../validators/required-file-type.validator';
import {requiredIf} from '../../validators/required-if.validator';

export interface Form990ezFormValues {
    name: string;
    ein: number;
    year: number;
    phone?: string;
    is_foreign_phone?: boolean;
    tax_period_start: string;
    tax_period_end: string;
    address: AddressFormValues;

    reasonable_cause_explanation: string;

    // General Info / Part 0 loaner fields due to paper form structuring.
    IntlRtrnInd: boolean;
    NmChngInd: boolean;
    NmChngUploader?: File;
    NmChngUploaderHasFile: boolean;
    AmnddRtrnInd: boolean;
    ApplctnPndngInd: boolean;
    AddrssChngInd: boolean;
    FnlRtrnInd: boolean;
    FnlRtrnIndUploader?: File;
    FnlRtrnIndUploaderHasFile: boolean;
    AccntngPrdChngInd: boolean;
    accountingPeriodChangeCheck?: string;
}

export interface Form990ezGeneralInfoFormValues {
    GrpExmptnNm: string;
    accountingMethod: string;
    accountingMethodOtherDesc: string;
    SkdBNtRqrdInd: boolean;
    WbstAddrssTxt: string;
    organizationType: string;
    organizationType501cType: string;
    typeOfOrganization: string;
    typeOfOrganizationOtherDesc: string;
    GrssRcptsAmt?: number;
}

export interface Form990ezPart1FormValues {
    InfInSkdOPrtIInd: boolean;
    CntrbtnsGftsGrntsEtcAmt?: number;
    PrgrmSrvcRvnAmt?: number;
    MmbrshpDsAmt?: number;
    InvstmntIncmAmt?: number;
    SlOfAsstsGrssAmt?: number;
    CstOrOthrBssExpnsSlAmt?: number;
    GnOrLssFrmSlOfAsstsAmt?: number;
    GmngGrssIncmAmt?: number;
    FndrsngGrssIncmAmt?: number;
    CntrRptFndrsngEvntAmt?: number;
    SpclEvntsDrctExpnssAmt?: number;
    SpclEvntsNtIncmLssAmt?: number;
    GrssSlsOfInvntryAmt?: number;
    CstOfGdsSldAmt?: number;
    GrssPrftLssSlsOfInvntryAmt?: number;
    OthrRvnTtlAmt?: number;
    ScheduleOOthrRvnTtlAmt?: Form990ScheduleOLineFormValues;
    TtlRvnAmt?: number;
    GrntsAndSmlrAmntsPdAmt?: number;
    ScheduleOGrntsAndSmlrAmntsPdAmt?: Form990ScheduleOLineFormValues;
    BnftsPdTOrFrMmbrsAmt?: number;
    SlrsOthrCmpEmplBnftAmt?: number;
    FsAndOthrPymtTIndCntrctAmt?: number;
    OccpncyRntUtltsAndMntAmt?: number;
    PrntngPblctnsPstgAmt?: number;
    OthrExpnssTtlAmt?: number;
    ScheduleOOthrExpnssTtlAmt?: Form990ScheduleOLineFormValues;
    TtlExpnssAmt?: number;
    ExcssOrDfctFrYrAmt?: number;
    NtAsstsOrFndBlncsBOYAmt?: number;
    OthrChngsInNtAsstsAmt?: number;
    ScheduleOOthrChngsInNtAsstsAmt?: Form990ScheduleOLineFormValues;
    NtAsstsOrFndBlncsEOYAmt?: number;
    specialConditions: Form990ezPart1SpecialConditionsFormValues[];
}

export interface Form990ezPart1SpecialConditionsFormValues {
    id: string;
    SpclCndtnDsc: string;
}

export interface Form990ezPart2FormValues {
    EZ_InfInSkdOPrtIIInd: boolean;
    CshSvngsAndInvstmnts_BOYAmt: number;
    CshSvngsAndInvstmnts_EOYAmt: number;
    LndAndBldngs_BOYAmt: number;
    LndAndBldngs_EOYAmt: number;
    OthrAsstsTtlDtl_BOYAmt: number;
    OthrAsstsTtlDtl_EOYAmt: number;
    ScheduleOOthrAsstsTtlDtl?: Form990ScheduleOLineFormValues;
    Frm990TtlAssts_BOYAmt: number;
    Frm990TtlAssts_EOYAmt: number;
    SmOfTtlLblts_BOYAmt: number;
    SmOfTtlLblts_EOYAmt: number;
    ScheduleOSmOfTtlLblts?: Form990ScheduleOLineFormValues;
    NtAsstsOrFndBlncs_BOYAmt: number;
    NtAsstsOrFndBlncs_EOYAmt: number;
}

export interface Form990ezPart3FormValues {
    InfInSkdOPrtIIIInd: boolean;
    PrmryExmptPrpsTxt: string;
    TtlPrgrmSrvcExpnssAmt: number;
    programServiceAccomplishments: Form990ezPart3ProgramServiceAccomplishmentFormValues[];
}

export interface Form990ezPart3ProgramServiceAccomplishmentFormValues {
    id?: string;
    DscrptnPrgrmSrvcAccmTxt: string;
    GrntsAndAllctnsAmt: number;
    FrgnGrntsInd: boolean;
    PrgrmSrvcExpnssAmt: number;
}

export interface Form990ezPart4FormValues {
    InfInSkdOPrtIVInd: boolean;
    trustedEmployees: Form990ezPart4OfficerDirectorsTrustedEmployeesFormValues[];
}

export interface Form990ezPart4OfficerDirectorsTrustedEmployeesFormValues {
    id?: string;
    TtlTxt: string;
    name: string;
    is_business: boolean;
    AvrgHrsPrWkDvtdTPsRt: number;
    CmpnstnAmt: number;
    EmplyBnftPrgrmAmt: number;
    ExpnsAccntOthrAllwncAmt: number;
}

export interface Form990ezPart5FormValues {
    EZ_InfInSkdOPrtVInd: boolean;
    EZ_ActvtsNtPrvslyRptInd: boolean;
    ScheduleOEZ_ActvtsNtPrvslyRptInd?: Form990ScheduleOLineFormValues;
    EZ_ChgMdTOrgnzngDcNtRptInd: boolean;
    ScheduleOEZ_ChgMdTOrgnzngDcNtRptInd?: Form990ScheduleOLineFormValues;
    EZ_OrgnztnHdUBIInd: boolean;
    EZ_OrgnztnFld990TInd: boolean;
    ScheduleOEZ_OrgnztnFld990TInd?: Form990ScheduleOLineFormValues;
    EZ_SbjctTPrxyTxInd: boolean;
    EZ_OrgnztnDsslvdEtcInd: boolean;
    EZ_DrctIndrctPltclExpndAmt: number;
    EZ_Frm1120PlFldInd: boolean;
    EZ_MdLnsTFrmOffcrsInd: boolean;
    EZ_LnsTFrmOffcrsAmt: number;
    EZ_InttnFsAndCpCntrAmt: number;
    EZ_GrssRcptsFrPblcUsAmt: number;
    EZ_TxImpsdUndrIRC4911Amt: number;
    EZ_TxImpsdUndrIRC4912Amt: number;
    EZ_TxImpsdUndrIRC4955Amt: number;
    EZ_EnggdInExcssBnftTrnsInd: boolean;
    EZ_TxImpsdOnOrgnztnMgrAmt: number;
    EZ_TxRmbrsdByOrgnztnAmt: number;
    EZ_PrhbtdTxShltrTrnsInd: boolean;
    statesWhereReturnIsFiled: string[];
    bookkeeper_name: string;
    bookkeeper_is_business: boolean;
    bookkeeper_address: AddressFormValues;
    BksInCrOfDtl_PhnNm: string;
    EZ_FrgnFnnclAccntInd: boolean;
    countriesWithForeignBankAccount: string[];
    EZ_FrgnOffcInd: boolean;
    countriesWithForeignOffice: string[];
    EZ_NctFlngFrm990Intrst: number;
    EZ_NECTFlngFrm990Ind: boolean;
    EZ_DnrAdvsdFndsInd: boolean;
    EZ_OprtHsptlInd: boolean;
    EZ_TnnngSrvcsPrvddInd: boolean;
    EZ_Frm720FldInd: boolean;
    ScheduleOEZ_Frm720FldInd?: Form990ScheduleOLineFormValues;
    EZ_RltdOrgnztnCtrlEntInd: boolean;
    EZ_TrnsctnWthCntrlEntInd: boolean;
    EZ_PltclCmpgnActyInd: boolean;
    OrganizationChangeUploader?: File;
    OrganizationChangeUploaderHasFile: boolean;
}

export interface Form990ezPart5StatesWhereReturnIsFiledFormValues {
    id?: string;
    SttsWhrCpyOfRtrnIsFldCd: string;
}

export interface Form990ezPart5CountriesWithForeignAccount {
    id?: string;
    FrgnFnnclAccntCntryCd: string;
}

export interface Form990ezPart5CountriesWithForeignOffice {
    id?: string;
    FrgnOffcCntryCd: string;
}

export interface Form990ezPart6FormValues {
    InfInSkdOPrtVIInd: boolean;
    LbbyngActvtsInd: boolean;
    SchlOprtngInd: boolean;
    TrnsfrExmptNnChrtblRltdOrgInd: boolean;
    RltdOrgSct527OrgInd: boolean;
    OthrEmplyPdOvr100kCnt: number;
    CntrctRcvdGrtrThn100KCnt: number;
    FldSkdAInd: boolean;
    highestPaidEmployees: Form990ezPart6HighestPaidEmployeesFormValues[];
    highestPaidContractors: Form990ezPart6HighestPaidContractorsFormValues[];
}

export interface Form990ezPart6HighestPaidEmployeesFormValues {
    id?: string;
    PrsnNm: string;
    TtlTxt: string;
    AvrgHrsPrWkRt: number;
    CmpnstnAmt: number;
    EmplyBnftsAmt: number;
    ExpnsAccntAmt: number;
}

export interface Form990ezPart6HighestPaidContractorsFormValues {
    id?: string;
    name: string;
    is_business: boolean;
    CmpnstnOfHghstPdCntrct_SrvcTxt: string;
    CmpnstnOfHghstPdCntrct_CmpnstnAmt: number;
    address: AddressFormValues;
}

export interface Form990ezSignatureFormValues {
    using_paid_preparer: boolean;
    is_taxpayer_present: boolean;
    preparer: PaidPreparerFormValues;
    officer_name: string;
    officer_title: string;
    officer_email: string;
}

@Injectable({providedIn: 'root'})
export class Form990ezFormService {

    public defaults = {
        subsection: null,
        base: {
            name: '',
            ein: 0,
            year: 2020,
            phone: '',
            is_foreign_phone: false,
            tax_period_start: '2020-01-01',
            tax_period_end: '2020-12-31',
            address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US'
            },

            reasonable_cause_explanation: '',

            IntlRtrnInd: false,
            NmChngInd: false,
            NmChngUploader: null,
            NmChngUploaderHasFile: false,
            AmnddRtrnInd: false,
            AddrssChngInd: false,
            ApplctnPndngInd: false,
            FnlRtrnInd: false,
            FnlRtrnIndUploader: null,
            FnlRtrnIndUploaderHasFile: false,
            AccntngPrdChngInd: false,
            accountingPeriodChangeCheck: null,
        },

        part0: {
            GrpExmptnNm: '',
            accountingMethod: '',
            accountingMethodOtherDesc: '',
            SkdBNtRqrdInd: false,
            WbstAddrssTxt: '',
            organizationType: '',
            organizationType501cType: '',
            typeOfOrganization: '',
            typeOfOrganizationOtherDesc: '',
            GrssRcptsAmt: null,
            specialConditions: []
        },

        part1: {
            InfInSkdOPrtIInd: false,
            CntrbtnsGftsGrntsEtcAmt: null,
            PrgrmSrvcRvnAmt: null,
            MmbrshpDsAmt: null,
            InvstmntIncmAmt: null,
            SlOfAsstsGrssAmt: null,
            CstOrOthrBssExpnsSlAmt: null,
            GnOrLssFrmSlOfAsstsAmt: null,
            GmngGrssIncmAmt: null,
            FndrsngGrssIncmAmt: null,
            CntrRptFndrsngEvntAmt: null,
            SpclEvntsDrctExpnssAmt: null,
            SpclEvntsNtIncmLssAmt: null,
            GrssSlsOfInvntryAmt: null,
            CstOfGdsSldAmt: null,
            GrssPrftLssSlsOfInvntryAmt: null,
            OthrRvnTtlAmt: null,
            ScheduleOOthrRvnTtlAmt: {
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 8',
                ExplanationTxt: ''
            },
            TtlRvnAmt: null,
            GrntsAndSmlrAmntsPdAmt: null,
            ScheduleOGrntsAndSmlrAmntsPdAmt: {
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 10',
                ExplanationTxt: ''
            },
            BnftsPdTOrFrMmbrsAmt: null,
            SlrsOthrCmpEmplBnftAmt: null,
            FsAndOthrPymtTIndCntrctAmt: null,
            OccpncyRntUtltsAndMntAmt: null,
            PrntngPblctnsPstgAmt: null,
            OthrExpnssTtlAmt: null,
            ScheduleOOthrExpnssTtlAmt: {
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 16',
                ExplanationTxt: ''
            },
            TtlExpnssAmt: null,
            ExcssOrDfctFrYrAmt: null,
            NtAsstsOrFndBlncsBOYAmt: null,
            OthrChngsInNtAsstsAmt: null,
            ScheduleOOthrChngsInNtAsstsAmt: {
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 20',
                ExplanationTxt: ''
            },
            NtAsstsOrFndBlncsEOYAmt: null,
        },

        part2: {
            EZ_InfInSkdOPrtIIInd: false,
            CshSvngsAndInvstmnts_BOYAmt: null,
            CshSvngsAndInvstmnts_EOYAmt: null,
            LndAndBldngs_BOYAmt: null,
            LndAndBldngs_EOYAmt: null,
            OthrAsstsTtlDtl_BOYAmt: null,
            OthrAsstsTtlDtl_EOYAmt: null,
            ScheduleOOthrAsstsTtlDtl: {
                id: null,
                FormAndLineReferenceDesc: 'Part II, Line 24',
                ExplanationTxt: ''
            },
            Frm990TtlAssts_BOYAmt: null,
            Frm990TtlAssts_EOYAmt: null,
            SmOfTtlLblts_BOYAmt: null,
            SmOfTtlLblts_EOYAmt: null,
            ScheduleOSmOfTtlLblts: {
                id: null,
                FormAndLineReferenceDesc: 'Part II, Line 26',
                ExplanationTxt: ''
            },
            NtAsstsOrFndBlncs_BOYAmt: null,
            NtAsstsOrFndBlncs_EOYAmt: null,
        },

        part3: {
            InfInSkdOPrtIIIInd: false,
            PrmryExmptPrpsTxt: '',
            TtlPrgrmSrvcExpnssAmt: null,
            programServiceAccomplishments: []
        },

        part4: {
            InfInSkdOPrtIVInd: false,
            trustedEmployees: []
        },

        part5: {
            EZ_InfInSkdOPrtVInd: false,
            EZ_ActvtsNtPrvslyRptInd: null,
            ScheduleOEZ_ActvtsNtPrvslyRptInd: {
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 33',
                ExplanationTxt: ''
            },
            EZ_ChgMdTOrgnzngDcNtRptInd: null,
            ScheduleOEZ_ChgMdTOrgnzngDcNtRptInd: {
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 34',
                ExplanationTxt: ''
            },
            EZ_OrgnztnHdUBIInd: null,
            EZ_OrgnztnFld990TInd: null,
            ScheduleOEZ_OrgnztnFld990TInd: {
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 35b',
                ExplanationTxt: ''
            },
            EZ_SbjctTPrxyTxInd: null,
            EZ_OrgnztnDsslvdEtcInd: null,
            EZ_DrctIndrctPltclExpndAmt: null,
            EZ_Frm1120PlFldInd: null,
            EZ_MdLnsTFrmOffcrsInd: null,
            EZ_LnsTFrmOffcrsAmt: null,
            EZ_InttnFsAndCpCntrAmt: null,
            EZ_GrssRcptsFrPblcUsAmt: null,
            EZ_TxImpsdUndrIRC4911Amt: null,
            EZ_TxImpsdUndrIRC4912Amt: null,
            EZ_TxImpsdUndrIRC4955Amt: null,
            EZ_EnggdInExcssBnftTrnsInd: null,
            EZ_TxImpsdOnOrgnztnMgrAmt: null,
            EZ_TxRmbrsdByOrgnztnAmt: null,
            EZ_PrhbtdTxShltrTrnsInd: null,
            statesWhereReturnIsFiled: [],
            bookkeeper_name: '',
            bookkeeper_is_business: null,
            BksInCrOfDtl_PhnNm: '',
            bookkeeper_address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US'
            },
            EZ_FrgnFnnclAccntInd: null,
            countriesWithForeignBankAccount: [],
            EZ_FrgnOffcInd: null,
            countriesWithForeignOffice: [],
            EZ_NctFlngFrm990Intrst: null,
            EZ_NECTFlngFrm990Ind: false,
            EZ_DnrAdvsdFndsInd: null,
            EZ_OprtHsptlInd: null,
            EZ_TnnngSrvcsPrvddInd: null,
            EZ_Frm720FldInd: null,
            ScheduleOEZ_Frm720FldInd: {
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 44d',
                ExplanationTxt: ''
            },
            EZ_RltdOrgnztnCtrlEntInd: null,
            EZ_TrnsctnWthCntrlEntInd: null,
            EZ_PltclCmpgnActyInd: null,
            OrganizationChangeUploader: null,
            OrganizationChangeUploaderHasFile: false,
        },

        part6: {
            InfInSkdOPrtVIInd: false,
            LbbyngActvtsInd: null,
            SchlOprtngInd: null,
            TrnsfrExmptNnChrtblRltdOrgInd: null,
            RltdOrgSct527OrgInd: null,
            OthrEmplyPdOvr100kCnt: 0,
            CntrctRcvdGrtrThn100KCnt: 0,
            FldSkdAInd: null,
            highestPaidEmployees: [],
            highestPaidContractors: [],
        },

        signature: {
            using_paid_preparer: false,
            is_taxpayer_present: true,
            preparer: {
                name: '',
                firm_name: '',
                ptin: '',
                address: {
                    line1: '',
                    line2: null,
                    city: '',
                    state: '',
                    zip: '',
                    country: 'US'
                },
                self_employed: false,
                ein: null,
                phone: null,
            },
            officer_name: '',
            officer_title: '',
            officer_email: '',
        },
    };

    public form = new FormGroup({
        subsection: new FormControl(null),
        base: new FormGroup({
            name: new FormControl('', Validators.required),
            ein: new FormControl(0, Validators.required),
            year: new FormControl(2020, Validators.required),
            phone: new FormControl(''),
            is_foreign_phone: new FormControl(false),
            tax_period_start: new FormControl('2020-01-01', Validators.required),
            tax_period_end: new FormControl('2020-12-31', Validators.required),
            address: new FormControl({
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US'
            }, Validators.required),

            reasonable_cause_explanation: new FormControl(''),

            IntlRtrnInd: new FormControl(false),
            NmChngInd: new FormControl(false),
            NmChngUploader: new FormControl(null, [requiredFileType('pdf')]),
            NmChngUploaderHasFile: new FormControl(false),
            AmnddRtrnInd: new FormControl(false),
            AddrssChngInd: new FormControl(false),
            ApplctnPndngInd: new FormControl(false),
            FnlRtrnInd: new FormControl(false),
            FnlRtrnIndUploader: new FormControl(null, [requiredFileType('pdf')]),
            FnlRtrnIndUploaderHasFile: new FormControl(false),
            AccntngPrdChngInd: new FormControl(false),
            accountingPeriodChangeCheck: new FormControl(null),
        }),

        part0: new FormGroup({
            GrpExmptnNm: new FormControl(''),
            accountingMethod: new FormControl('', Validators.required),
            accountingMethodOtherDesc: new FormControl(''),
            SkdBNtRqrdInd: new FormControl(false),
            WbstAddrssTxt: new FormControl(''),
            organizationType: new FormControl('', Validators.required),
            organizationType501cType: new FormControl('', Validators.pattern('([2,4-9]|1[0-9]|2[0,2-7])$')),
            typeOfOrganization: new FormControl('', Validators.required),
            typeOfOrganizationOtherDesc: new FormControl(''),
            GrssRcptsAmt: new FormControl(null, Validators.required),
            specialConditions: new FormArray([])
        }),

        part1: new FormGroup({
            InfInSkdOPrtIInd: new FormControl(false),
            CntrbtnsGftsGrntsEtcAmt: new FormControl(null),
            PrgrmSrvcRvnAmt: new FormControl(null),
            MmbrshpDsAmt: new FormControl(null),
            InvstmntIncmAmt: new FormControl(null),
            SlOfAsstsGrssAmt: new FormControl(null),
            CstOrOthrBssExpnsSlAmt: new FormControl(null),
            GnOrLssFrmSlOfAsstsAmt: new FormControl(null),
            GmngGrssIncmAmt: new FormControl(null),
            FndrsngGrssIncmAmt: new FormControl(null),
            CntrRptFndrsngEvntAmt: new FormControl(null),
            SpclEvntsDrctExpnssAmt: new FormControl(null),
            SpclEvntsNtIncmLssAmt: new FormControl(null),
            GrssSlsOfInvntryAmt: new FormControl(null),
            CstOfGdsSldAmt: new FormControl(null),
            GrssPrftLssSlsOfInvntryAmt: new FormControl(null),
            OthrRvnTtlAmt: new FormControl(null),
            ScheduleOOthrRvnTtlAmt: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 8',
                ExplanationTxt: ''
            }),
            TtlRvnAmt: new FormControl(null),
            GrntsAndSmlrAmntsPdAmt: new FormControl(null),
            ScheduleOGrntsAndSmlrAmntsPdAmt: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 10',
                ExplanationTxt: ''
            }),
            BnftsPdTOrFrMmbrsAmt: new FormControl(null),
            SlrsOthrCmpEmplBnftAmt: new FormControl(null),
            FsAndOthrPymtTIndCntrctAmt: new FormControl(null),
            OccpncyRntUtltsAndMntAmt: new FormControl(null),
            PrntngPblctnsPstgAmt: new FormControl(null),
            OthrExpnssTtlAmt: new FormControl(null),
            ScheduleOOthrExpnssTtlAmt: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 16',
                ExplanationTxt: ''
            }),
            TtlExpnssAmt: new FormControl(null),
            ExcssOrDfctFrYrAmt: new FormControl(null),
            NtAsstsOrFndBlncsBOYAmt: new FormControl(null),
            OthrChngsInNtAsstsAmt: new FormControl(null),
            ScheduleOOthrChngsInNtAsstsAmt: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part I, Line 20',
                ExplanationTxt: ''
            }),
            NtAsstsOrFndBlncsEOYAmt: new FormControl(null),
        }),

        part2: new FormGroup({
            EZ_InfInSkdOPrtIIInd: new FormControl(false),
            CshSvngsAndInvstmnts_BOYAmt: new FormControl(null),
            CshSvngsAndInvstmnts_EOYAmt: new FormControl(null),
            LndAndBldngs_BOYAmt: new FormControl(null),
            LndAndBldngs_EOYAmt: new FormControl(null),
            OthrAsstsTtlDtl_BOYAmt: new FormControl(null),
            OthrAsstsTtlDtl_EOYAmt: new FormControl(null),
            ScheduleOOthrAsstsTtlDtl: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part II, Line 24',
                ExplanationTxt: ''
            }),
            Frm990TtlAssts_BOYAmt: new FormControl(null, Validators.required),
            Frm990TtlAssts_EOYAmt: new FormControl(null, Validators.required),
            SmOfTtlLblts_BOYAmt: new FormControl(null),
            SmOfTtlLblts_EOYAmt: new FormControl(null),
            ScheduleOSmOfTtlLblts: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part II, Line 26',
                ExplanationTxt: ''
            }),
            NtAsstsOrFndBlncs_BOYAmt: new FormControl(null, Validators.required),
            NtAsstsOrFndBlncs_EOYAmt: new FormControl(null, Validators.required),
        }),

        part3: new FormGroup({
            InfInSkdOPrtIIIInd: new FormControl(false),
            PrmryExmptPrpsTxt: new FormControl('', Validators.required),
            TtlPrgrmSrvcExpnssAmt: new FormControl(null),
            programServiceAccomplishments: new FormArray([], [Validators.required, Validators.minLength(1)])
        }),

        part4: new FormGroup({
            InfInSkdOPrtIVInd: new FormControl(false),
            trustedEmployees: new FormArray([], [Validators.required, Validators.minLength(1)])
        }),

        part5: new FormGroup({
            EZ_InfInSkdOPrtVInd: new FormControl(false),
            EZ_ActvtsNtPrvslyRptInd: new FormControl(null, Validators.required),
            ScheduleOEZ_ActvtsNtPrvslyRptInd: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 33',
                ExplanationTxt: ''
            }),
            EZ_ChgMdTOrgnzngDcNtRptInd: new FormControl(null, Validators.required),
            ScheduleOEZ_ChgMdTOrgnzngDcNtRptInd: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 34',
                ExplanationTxt: ''
            }),
            EZ_OrgnztnHdUBIInd: new FormControl(null),
            EZ_OrgnztnFld990TInd: new FormControl(null),
            ScheduleOEZ_OrgnztnFld990TInd: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 35b',
                ExplanationTxt: ''
            }),
            EZ_SbjctTPrxyTxInd: new FormControl(null, Validators.required),
            EZ_OrgnztnDsslvdEtcInd: new FormControl(null, Validators.required),
            EZ_DrctIndrctPltclExpndAmt: new FormControl(null),
            EZ_Frm1120PlFldInd: new FormControl(null),
            EZ_MdLnsTFrmOffcrsInd: new FormControl(null, Validators.required),
            EZ_LnsTFrmOffcrsAmt: new FormControl(null),
            EZ_InttnFsAndCpCntrAmt: new FormControl(null),
            EZ_GrssRcptsFrPblcUsAmt: new FormControl(null),
            EZ_TxImpsdUndrIRC4911Amt: new FormControl(null),
            EZ_TxImpsdUndrIRC4912Amt: new FormControl(null),
            EZ_TxImpsdUndrIRC4955Amt: new FormControl(null),
            EZ_EnggdInExcssBnftTrnsInd: new FormControl(null),
            EZ_TxImpsdOnOrgnztnMgrAmt: new FormControl(null),
            EZ_TxRmbrsdByOrgnztnAmt: new FormControl(null),
            EZ_PrhbtdTxShltrTrnsInd: new FormControl(null),
            statesWhereReturnIsFiled: new FormControl([]),
            bookkeeper_name: new FormControl('', Validators.required),
            bookkeeper_is_business: new FormControl(null),
            BksInCrOfDtl_PhnNm: new FormControl('', Validators.required),
            bookkeeper_address: new FormControl({
                line1: '',
                line2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US'
            }, Validators.required),
            EZ_FrgnFnnclAccntInd: new FormControl(null),
            countriesWithForeignBankAccount: new FormControl([]),
            EZ_FrgnOffcInd: new FormControl(null),
            countriesWithForeignOffice: new FormControl([]),
            EZ_NctFlngFrm990Intrst: new FormControl(null),
            EZ_NECTFlngFrm990Ind: new FormControl(false),
            EZ_DnrAdvsdFndsInd: new FormControl(null, Validators.required),
            EZ_OprtHsptlInd: new FormControl(null, Validators.required),
            EZ_TnnngSrvcsPrvddInd: new FormControl(null, Validators.required),
            EZ_Frm720FldInd: new FormControl(null),
            ScheduleOEZ_Frm720FldInd: new FormControl({
                id: null,
                FormAndLineReferenceDesc: 'Part V, Line 44d',
                ExplanationTxt: ''
            }),
            EZ_RltdOrgnztnCtrlEntInd: new FormControl(null, Validators.required),
            EZ_TrnsctnWthCntrlEntInd: new FormControl(null),
            EZ_PltclCmpgnActyInd: new FormControl(null),
            OrganizationChangeUploader: new FormControl(null, [requiredFileType('pdf')]),
            OrganizationChangeUploaderHasFile: new FormControl(false),
        }),

        part6: new FormGroup({
            InfInSkdOPrtVIInd: new FormControl(false),
            LbbyngActvtsInd: new FormControl(null),
            SchlOprtngInd: new FormControl(null),
            TrnsfrExmptNnChrtblRltdOrgInd: new FormControl(null),
            RltdOrgSct527OrgInd: new FormControl(null),
            OthrEmplyPdOvr100kCnt: new FormControl(0),
            CntrctRcvdGrtrThn100KCnt: new FormControl(0),
            FldSkdAInd: new FormControl(null),
            highestPaidEmployees: new FormArray([]),
            highestPaidContractors: new FormArray([]),
        }),

        signature: new FormGroup({
            using_paid_preparer: new FormControl(false),
            is_taxpayer_present: new FormControl(true),
            preparer: new FormControl({
                name: '',
                firm_name: '',
                ptin: '',
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: 'US'
                },
                self_employed: false,
                ein: null,
                phone: null,
            }),
            officer_name: new FormControl(''),
            officer_title: new FormControl(''),
            officer_email: new FormControl(''),
        }),
    });

    constructor() {
        // Validators for fields that may need required validation, depending on a different field's answer.

        // Part 0 - Organization type determines if 501c organization number is required.
        (this.form.controls.part0 as FormGroup).controls.organizationType501cType.setValidators([
            requiredIf(() => ((this.form.get('part0') as FormGroup).controls.organizationType.value === '501c')),
            Validators.pattern('([2,4-9]|1[0-9]|2[0,2-7])$')
        ]);

        // Part 0 - Organization type determines if Part V - Line 35a is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_OrgnztnHdUBIInd.setValidators([
            requiredIf(() => {
                return ((this.form.get('part0') as FormGroup).controls.organizationType.value !== '527');
            })
        ]);

        // Part 0 - Organization type determines if Part V - Line 35a is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_OrgnztnFld990TInd.setValidators([
            requiredIf(() => {
                return ((this.form.get('part5') as FormGroup).controls.EZ_OrgnztnHdUBIInd.value === true);
            })
        ]);

        // Part V - Line 38a determines if Line 38b is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_LnsTFrmOffcrsAmt.setValidators([
            requiredIf(() => ((this.form.get('part5') as FormGroup).controls.EZ_MdLnsTFrmOffcrsInd.value === true))
        ]);

        // Part 0 - Organization type determines if Part V - Line 39a is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_InttnFsAndCpCntrAmt.setValidators([
            requiredIf(
                () => { return ((this.form.get('part0') as FormGroup).controls.organizationType.value === '501c') &&
                    ((this.form.get('part0') as FormGroup).controls.organizationType501cType.value === '7'); }
            )
        ]);

        // Part 0 - Organization type determines if Part V - Line 39b is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_GrssRcptsFrPblcUsAmt.setValidators([
            requiredIf(
                () => { return ((this.form.get('part0') as FormGroup).controls.organizationType.value === '501c') &&
                    ((this.form.get('part0') as FormGroup).controls.organizationType501cType.value === '7'); }
            )
        ]);

        // Part 0 - Organization type determines if Part V - Line 40b is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_EnggdInExcssBnftTrnsInd.setValidators([
            requiredIf(
                () => { return ((this.form.get('part0') as FormGroup).controls.organizationType.value === '501c3') ||
                    (((this.form.get('part0') as FormGroup).controls.organizationType.value === '501c') &&
                    ((this.form.get('part0') as FormGroup).controls.organizationType501cType.value === '4')); }
            )
        ]);

        // Part 0 - Organization type determines if Part V - Line 43b is required.
        (this.form.controls.part5 as FormGroup).controls.EZ_NctFlngFrm990Intrst.setValidators([
            requiredIf(
                () => { return ((this.form.get('part5') as FormGroup).controls.EZ_NECTFlngFrm990Ind.value === true) &&
                    ((this.form.get('part0') as FormGroup).controls.organizationType.value === '4947a1'); }
            )
        ]);
    }

    reset() {
        this.form.enable({onlySelf: true, emitEvent: false});
        ((this.form.controls.part0 as FormGroup).controls.specialConditions as FormArray).clear();
        ((this.form.controls.part3 as FormGroup).controls.programServiceAccomplishments as FormArray).clear();
        ((this.form.controls.part4 as FormGroup).controls.trustedEmployees as FormArray).clear();
        ((this.form.controls.part6 as FormGroup).controls.highestPaidEmployees as FormArray).clear();
        ((this.form.controls.part6 as FormGroup).controls.highestPaidContractors as FormArray).clear();
        this.form.reset(this.defaults);
    }
}
