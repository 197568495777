import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import packageInfo from '../package.json';
import { gitVersion } from './environments/git-version';

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: environment.sentry.dsn,
        environment: environment.envName,
        release: packageInfo.name + '@' + gitVersion.commit,
        debug: !environment.production,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Integrations.BrowserTracing({
                tracingOrigins: ['localhost', 'com.simple990:8080', environment.api.replace(/https?:\/\//, '')],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
